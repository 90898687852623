<template>
  <div id="app" ref="appRef">
    <div class="container-fluid pcg-page-container" :class="{ 'my-pcg-header': !signedIn && isLoginPath, 'my-page-container': !signedIn && isLoginPath }">
      <div v-if="showUpdateBanner === 1 && updateInProgress !== 1" class="update-banner">
        <div>{{ updateBannerText }}</div>
      </div>
      <div v-if="updateInProgress === 1" class="pcg-body-container update-text">
        <div>{{ $t('general.update_application_text_1') }}</div>
        <div>{{ $t('general.update_application_text_2') }}</div>
      </div>
      <dn-layout-header v-if="updateInProgress !== 1" :logo="require('../assets/images/logo-science.svg')"
                     :negative-logo="require('../assets/images/logo-science2.svg')"
                     :logoContainerClasses="sidebarMenuClasses" :style="{ marginTop: showUpdateBanner === 1 ? '40px' : '0px' }"
                     class="my-layout-header">
        <template v-slot:upper>
          <pcg-header-fonts class="icon-padding" :decrease-font="$t('general.decrease_font')" :enlarge-font="$t('general.enlarge_font')" v-on:click.native="calculateHeader"/>
          <pcg-header-contrast class="icon-padding" :switch-contrast="$t('general.change_contrast')"/>
          <pcg-language-selector class="pcg-lang" v-if="changeLanguage" :supportedLanguages="supportedLanguages"/>
        </template>
        <template v-slot:bottom>
          <pcg-header-navbar tabindex="-1" id="firstFocus" ref="headerNavbar" :class="{ 'pcg-12': fontSize === 12, 'pcg-14': fontSize === 14, 'pcg-16': fontSize === 16, 'pcg-18': fontSize === 18, 'pcg-20': fontSize === 20, }">
            <pcg-btn v-if="isLoginPath && !isWrongTenant" size="small" class="login-button ml-auto" @click="userLogin">{{ $t('components.dn_navigation_menu.log_in') }}</pcg-btn>
            <pcg-btn v-if="isLoginPath && isWrongTenant" size="small" class="login-button ml-auto" @click="userWrongLogout">{{ $t('components.dn_navigation_menu.logout') }} {{ wrongEmail }}</pcg-btn>
            <div class="text-center" v-if="!isLoginPath">
              <pcg-header-user-name class="username" :user-name="fullName" v-if="signedIn"/>
              <pcg-header-profile-button :currentRole="currentRole"
                                         :roles="roles"
                                         :supportedLanguages="supportedLanguages"
                                         @logout="userLogout"
                                         v-if="signedIn"
                                         @changeRole="changeRole"
                                         :avatar="getAvatar()"
              >
                <b-dropdown-item v-if="hasRight('local_token')" href="#" class="pcg-profile-option" @click="$refs.genToken.show()">
                  <div class="pcg-font-icon pcg-icon-bell"></div>
                  <div class="pcg-profile-option-txt">
                    <span>{{ $t("components.pcg_header_profile_button.gen_local_token") }}</span>
                  </div>
                  <b-modal ref="genToken"
                           id="gen-token"
                           :hide-header="true"
                           footer-class="my-modal-footer"
                           content-class="my-modal-content"
                           :centered="true"
                           v-bind:ok-title="$t('general.hide')"
                           ok-variant="primary"
                           :no-close-on-esc="false"
                           @ok="localToken = null"
                           :ok-only="true"
                  >
                    <div class="modal-header">{{ $t('components.pcg_header_profile_button.local_token') }}</div>
                    <div v-if="localToken" class="mt-1" style="overflow-wrap: anywhere">
                      {{ localToken }}
                    </div>
                    <pcg-btn v-else @click="genLocalToken">{{ $t('components.pcg_header_profile_button.gen_local_token') }}</pcg-btn>
                  </b-modal>
                </b-dropdown-item>
                <b-dropdown-item v-if="hasRight('select_evaluation_period')" href="#" class="pcg-profile-option" @click="showEvaluationModal">
                  <div class="pcg-font-icon pcg-icon-calendar"></div>
                  <div class="pcg-profile-option-txt">
                    <span>{{ $t("components.pcg_header_profile_button.change_evaluation_period") }}</span>
                  </div>
                  <b-modal ref="changeEvaluationPeriod"
                           id="change-evaluation-period"
                           :hide-header="true"
                           :centered="true"
                           v-bind:ok-title="$t('general.confirm')"
                           ok-variant="primary"
                           :no-close-on-esc="true"
                           @ok="updateEvaluationPeriod"
                           @hide="escEvaluation"
                           v-bind:cancel-title="$t('general.cancel')"
                           cancel-variant="default"
                           content-class="my-modal-content"
                           footer-class="my-modal-footer"
                  >
                    <div class="confirm-header">{{ $t('components.pcg_header_profile_button.changing_evaluation_period') }}</div>
                    <pcg-select
                        :options="availableEvaluations"
                        :default-option="{ text: $t('general.select'), id: '' }"
                        v-model="activeEvaluation"
                    />
                  </b-modal>
                </b-dropdown-item>
              </pcg-header-profile-button>
            </div>
          </pcg-header-navbar>
        </template>
      </dn-layout-header>
      <b-row v-if="updateInProgress !== 1" class="pcg-body-container" :style="{ marginTop: showUpdateBanner === 1 ? '40px' : '0px' }">
        <nav v-if="!isLoginPath" :aria-label="$t('general.main_menu')">
          <pcg-navigation-menu v-if="!isLoginPath" :current-role="currentRole"
                               :signed-in="signedIn"
                               :links="links">
          </pcg-navigation-menu>
        </nav>
        <div id="content" class="col main-content-margin" :class="{ 'small-margin': !menuOpen }">
          <main>
            <div class="row">
              <pcg-menu-switcher v-if="!isLoginPath && signedIn"/>
              <div class="pcg-content-container" v-if="checkForNetworkError" tabindex="-1" id="firstContent">
                <transition name="slide" mode="out-in" v-if="subDomain && subDomain === 'global' && $route.name === 'academies_list_path'">
                  <router-view :name="subDomain"/>
                </transition>
                <transition name="slide" mode="out-in" v-else>
                  <router-view/>
                </transition>
              </div>
            </div>
          </main>
          <footer>
            <div class="row align-items-end">
              <dn-layout-footer :showAccessibleDeclaration="showAccessibleDeclaration"></dn-layout-footer>
            </div>
          </footer>
        </div>
      </b-row>
    </div>
    <new-employee-modal modal-id="new-employee-modal" v-if="signedIn"/>
  </div>
</template>
<script>
import LayoutHeader from 'pcg-core-vue/src/components/LayoutHeader'
import DnLayoutFooter from '../components/DnLayoutFooter'
import PcgHeaderProfileButton from 'pcg-core-vue/src/components/PcgHeaderProfileButton'
import PcgHeaderUserName from 'pcg-core-vue/src/components/PcgHeaderUserName'
import PcgHeaderNavbar from 'pcg-core-vue/src/components/PcgHeaderNavbar'
import PcgLanguageSelector from 'pcg-core-vue/src/components/PcgLanguageSelector'
import PcgHeaderContrast from 'pcg-core-vue/src/components/PcgHeaderContrast'
import PcgHeaderFonts from 'pcg-core-vue/src/components/PcgHeaderFonts'
import PcgMenuSwitcher from 'pcg-core-vue/src/components/PcgMenuSwitcher'
import DnFooter from '../components/DnFooter'
import DnLayoutHeader from '../components/DnLayoutHeader'
import WindowResize from '../mixins/window_resize'
import { mapGetters } from 'vuex'
import supportedLanguages from '../translations/supported_langs'
import hasRight from '../mixins/has_right'
import ApiAuth from '../api/auth'
import ApiUsers from '../api/users'
import NewEmployeeModal from '../components/NewEmployeeModal'
import AcademyApi from '../api/academies'
import VueCookies from 'vue-cookies'
import Vue from 'vue'
import ApiEvaluations from '../api/evaluations'
Vue.use(VueCookies)

export default {
  components: {
    NewEmployeeModal,
    PcgHeaderProfileButton,
    PcgHeaderUserName,
    PcgHeaderNavbar,
    PcgLanguageSelector,
    PcgHeaderContrast,
    PcgHeaderFonts,
    LayoutHeader,
    PcgMenuSwitcher,
    DnFooter,
    DnLayoutHeader,
    DnLayoutFooter
  },
  mixins: [hasRight, WindowResize],
  data: function () {
    return {
      supportedLanguages: supportedLanguages,
      beforeRoute: null,
      employee_id: 0,
      fontSize: null,
      localLogin: false,
      loginView: false,
      links: [],
      originalTitle: 0,
      titleWidth: 0,
      checkUserInterval: null,
      subDomain: null,
      checkWrongTenantInterval: null,
      isWrongTenant: false,
      loginFooter: null,
      wrongEmail: null,
      changeLanguage: null,
      localToken: null,
      activeEvaluation: null,
      availableEvaluations: [],
      showUpdateBanner: false,
      updateBannerText: null,
      updateInProgress: false,
      checkUpdateInterval: null
    }
  },
  computed: {
    ...mapGetters('menu', ['menuOpen']),
    ...mapGetters('auth', ['signingOut', 'signedIn', 'roles', 'currentRole', 'fullName', 'employeeId', 'rights', 'expire']),
    ...mapGetters('sidebarMenu', ['sidebarMenuClasses']),
    ...mapGetters('page', ['contrast']),
    ...mapGetters('period', ['years']),
    normalLayout () {
      return !this.fullScreenLayout
    },
    checkForNetworkError () {
      if (!this.signedIn && !this.isLoginPath && this.$route.name !== 'dev_sessions_path' && this.$route.name !== 'accessibility_declaration_path' && this.$route.name !== 'no_rights_path') return false
      return true
    },
    fullScreenLayout () {
      return this.$route.name === 'no_rights_path'
    },
    isLoginPath () {
      return ['login_path', 'root_path'].includes(this.$route.name)
    },
    showAccessibleDeclaration () {
      return process.env.ACCESSIBILITY_DECLARATION == 1
    }
  },
  created () {
    this.getCookie()
    AcademyApi.getUpdateInformation()
        .then(response => {
          this.showUpdateBanner = response.data.showUpdateBanner
          this.updateBannerText = response.data.updateBannerText
          this.updateInProgress = response.data.updateInProgress
        })
    if (this.checkUpdateInterval === null) {
      this.checkUpdateInterval = setInterval(this.checkUpdate, 10000)
    }
    AcademyApi.getFooter()
      .then(response => {
        this.loginFooter = response.data.footerLogin
      })
    AcademyApi.getLanguageMode()
      .then(response => {
        this.changeLanguage = response.data.editLanguage
      })
    if (this.$route.query.uid) {
      const uid = this.$route.query.uid
      this.$store.dispatch('auth/redirSignIn', { id: uid })
        .then(response => {
          const redirectTo = this.$store.getters['auth/getRedirectTo']
          if (Vue.$cookies.get('redirect_url')) {
            let url = window.location.origin
            url += Vue.$cookies.get('redirect_url')
            Vue.$cookies.remove('redirect_url')
            setTimeout(() => {
              window.location = url
            }, 2000)
          }
          if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
            clearInterval(this.checkUserInterval)
            this.$router.push({ name: 'no_rights_path' }).catch(() => {})
          } else if (redirectTo && redirectTo.name) {
            this.$store.dispatch('auth/clearRedirectTo')
            this.$router.push(redirectTo)
          } else {
            // TODO go to user dashboard
            this.$router.push({ name: 'root_path' }).catch(error => {
              console.log(error.message)
            })
            // this.$router.push({ name: 'user_dashboard_path' })
          }
        })
        .catch(error => {
          console.log(error.message)
          this.$toastr.e(error.message)
        })
    }
    if (process.env.LOCAL_LOGIN == '1') {
      this.localLogin = true
    }
    // eslint-disable-next-line eqeqeq
    // if (this.signedIn && this.checkUserInterval === null && process.env.RAILS_ENV !== 'development') {
    if (this.signedIn && this.checkUserInterval === null) {
      // this.checkUser()
      this.checkUserInterval = setInterval(this.checkUser, 60000)
    }
    if (!this.signedIn && this.checkWrongTenantInterval === null) {
      this.checkWrongTenantInterval = setInterval(this.getCookie, 2000)
    }
    if (this.signedIn && this.expire) {
      // this.$store.dispatch('auth/signOut')
      // this.$router.push({ name: 'login_path' })
      this.checkUser()
    }
    this.$store.dispatch('header/setSiteTitle', 'Analityka i ewidencja naukowa')
    this.$store.dispatch('header/clearHeader')
    this.employee_id = this.$store.getters['auth/employeeId']
    const s = document.querySelector('#app-loader').dataset.subDomain
    this.subDomain = s.length > 0 ? s : null
    this.$store.dispatch('page/setSubDomain', this.subDomain)
  },
  mounted () {
    this.$store.dispatch('locale/setInitialLocale', this.supportedLanguages)
    if (this.signedIn) {
      ApiEvaluations.getEvaluations()
          .then(result => {
            result.data.datas.forEach(e => {
              this.availableEvaluations.push({ text: e.name, value: e.id })
            })
          })
          .catch(error => {
            console.log(error)
          })
      this.$store.dispatch('period/setInitialYears')
    }
    const html = document.documentElement
    if (this.$i18n.locale === 'pl') {
      html.setAttribute('lang', 'pl')
    } else {
      html.setAttribute('lang', 'en')
    }
    this.$root.$on('menuToggle', (toggle) => {
      this.menuOpen = toggle
    })
    this.fontSize = 14
    document.body.classList.forEach(cl => {
      if (cl === 'pcg-size-12' || cl === 'pcg-size-14' || cl === 'pcg-size-16' || cl === 'pcg-size-18' || cl === 'pcg-size-20') {
        this.fontSize = Number(cl.match(/\d/g).join(''))
      }
    })
    this.buildLinks()
    setTimeout(() => {
      document.getElementById('app-loader').style.display = 'none'
      if (this.windowWidth < 768 && this.isLoginPath) {
        document.getElementsByClassName('pcg-menu-btn')[0].style.display = 'none'
      }
      this.isLoading = false
      this.beforeRoute = this.$route.name
      this.calculateHeader()
      this.buildLinks()
    }, 2000)
    // window.addEventListener('storage', this.updateStorage)
  },
  // beforeDestroy () {
  //   window.removeEventListener('storage', this.updateStorage)
  // },
  watch: {
    employeeId () {
      this.buildLinks()
      this.calculateHeader()
      setTimeout(() => {
        this.buildLinks()
      }, 300)
    },
    windowWidth (newValue) {
      this.calculateSubtitleWidth()
      if (this.windowWidth < 768 && this.isLoginPath) {
        document.getElementsByClassName('pcg-menu-btn')[0].style.display = 'none'
      } else {
        document.getElementsByClassName('pcg-menu-btn')[0].style.display = 'visible'
      }
    },
    '$route.fullPath': {
      deep: true,
      handler () {
        if (this.beforeRoute === this.$route.name) {
          setTimeout(() => {
            this.calculateHeader()
          }, 1500)
        } else {
          setTimeout(() => {
            this.originalTitle = 0
            this.calculateHeader()
          }, 1500)
        }
        this.beforeRoute = this.$route.name
      }
    },
    '$route.path': {
      deep: true,
      handler () {
        if (this.beforeRoute !== this.$route.name) {
          setTimeout(() => {
            document.getElementById('firstFocus').focus()
          }, 1500)
        }
      }
    },
    rights: {
      deep: true,
      handler () {
        this.buildLinks()
        setTimeout(() => {
          this.buildLinks()
        }, 300)
      }
    },
    signedIn (newValue) {
      if (newValue) {
        this.fetchUser()
        // if (this.checkUserInterval === null && process.env.RAILS_ENV !== 'development') {
        if (this.checkUserInterval === null) {
          this.checkUserInterval = setInterval(this.checkUser, 60000)
        }
        ApiEvaluations.getEvaluations()
            .then(result => {
              result.data.datas.forEach(e => {
                this.availableEvaluations.push({ text: e.name, value: e.id })
              })
            })
            .catch(error => {
              console.log(error)
            })
        this.$store.dispatch('period/setInitialYears')
      } else if (!newValue && !this.signingOut) {
        console.log('SignedIn logout')
        this.userLogout()
      }
    },
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.buildLinks()
        setTimeout(() => {
          this.buildLinks()
        }, 300)
        const html = document.documentElement
        html.setAttribute('lang', newLocale)
        let titleLength = 0
        setTimeout(() => {
          if (this.originalTitle !== 0) {
            this.$refs.headerNavbar.$forceUpdate()
            titleLength = (this.$refs.headerNavbar.$children[0].$el.children[0].childNodes[0].length - 10)
            this.originalTitle = titleLength * 23
          }
          this.calculateHeader()
        }, 100)
      }
    },
    menuOpen: {
      deep: true,
      handler () {
        if (!this.menuOpen) {
          setTimeout(() => {
            this.calculateSubtitleWidth()
          }, 400)
        } else {
          this.calculateSubtitleWidth()
        }
      }
    }
  },
  methods: {
    showEvaluationModal () {
      this.activeEvaluation = parseInt(Vue.$cookies.get('evaluationId'))
      this.$refs.changeEvaluationPeriod.show()
    },
    updateEvaluationPeriod () {
      Vue.$cookies.set('evaluationId', this.activeEvaluation)
      this.$router.go(0)
    },
    escEvaluation () {
      this.$refs.changeEvaluationPeriod.hide()
    },
    goToContent () {
      this.$nextTick(() => {
        document.getElementById('firstContent').focus()
      })
    },
    getCookie () {
      if (Vue.$cookies.get('wrong_login')) {
        this.isWrongTenant = true
        this.wrongEmail = Vue.$cookies.get('wrong_login')
      }
      return this.isWrongTenant
    },
    genLocalToken () {
      ApiUsers.genLocalToken()
        .then((result) => {
          this.localToken = result.data.token
        })
        .catch((error) => {
          console.log(error)
        })
    },
    calculateHeader () {
      let font = null
      document.body.classList.forEach(cl => {
        if (cl === 'pcg-size-12' || cl === 'pcg-size-14' || cl === 'pcg-size-16' || cl === 'pcg-size-18' || cl === 'pcg-size-20') {
          const clInt = Number(cl.match(/\d/g).join(''))
          if (this.fontSize === 12) {
            if (clInt === 14) {
              this.originalTitle = this.originalTitle * 1.18
            }
          }
          if (this.fontSize === 14) {
            if (clInt === 12) {
              this.originalTitle = this.originalTitle * 0.865
            }
            if (clInt === 16) {
              this.originalTitle = this.originalTitle * 1.15
            }
          }
          if (this.fontSize === 16) {
            if (clInt === 14) {
              this.originalTitle = this.originalTitle * 0.88
            }
            if (clInt === 18) {
              this.originalTitle = this.originalTitle * 1.135
            }
          }
          if (this.fontSize === 18) {
            if (clInt === 16) {
              this.originalTitle = this.originalTitle * 0.9
            }
            if (clInt === 20) {
              this.originalTitle = this.originalTitle * 1.12
            }
          }
          if (this.fontSize === 20) {
            if (clInt === 18) {
              this.originalTitle = this.originalTitle * 0.91
            }
          }
          font = clInt
        }
      })
      if (font) {
        this.fontSize = font
      }
      this.calculateTitleWidth()
      this.calculateSubtitleWidth()
    },
    calculateTitleWidth () {
      if (this.updateInProgress === 1 || !this.$refs.headerNavbar.$children[0].$el.children) { return }
      if (this.signedIn) {
        if (this.menuOpen) {
          this.$refs.headerNavbar.$el.style.width = (this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth - 270) + 'px'
        } else {
          this.$refs.headerNavbar.$el.style.width = (this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth - 100) + 'px'
        }
        if (this.originalTitle === 0) {
          this.originalTitle = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
        }
        this.titleWidth = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
      } else {
        this.originalTitle = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
        this.titleWidth = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
      }
    },
    calculateSubtitleWidth () {
      if (this.updateInProgress === 1 || !this.$refs.headerNavbar.$children[0].$el.children) { return }
      const appWidth = this.$refs.headerNavbar.$root.$el.clientWidth
      let loginWidth = 0
      let navbarWidth = 0
      if (this.signedIn) {
        if (this.menuOpen) {
          this.$refs.headerNavbar.$el.style.width = (this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth - 270) + 'px'
        } else {
          this.$refs.headerNavbar.$el.style.width = (this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth - 100) + 'px'
        }
        if (appWidth < 753) {
          this.$refs.headerNavbar.$el.style.width = this.$refs.headerNavbar.$root.$el.childNodes[0].clientWidth + 'px'
        }
      }
      if (appWidth === 753) {
        this.$refs.headerNavbar.$children[0].$el.children[0].style.width = 280 + 'px'
        if (this.$refs.headerNavbar.$children.length > 2) {
          loginWidth = this.$refs.headerNavbar.$children[1].$el.offsetWidth + this.$refs.headerNavbar.$children[2].$el.offsetWidth + 20
        } else {
          loginWidth = this.$refs.headerNavbar.$children[1].$el.offsetWidth + 20
        }
        navbarWidth = this.$refs.headerNavbar.$el.clientWidth - 120
      } else {
        if (this.$refs.headerNavbar.$children.length > 2) {
          loginWidth = this.$refs.headerNavbar.$children[1].$el.offsetWidth + this.$refs.headerNavbar.$children[2].$el.offsetWidth + 20
        } else {
          loginWidth = this.$refs.headerNavbar.$children[1].$el.offsetWidth + 20
        }
        navbarWidth = this.$refs.headerNavbar.$el.clientWidth - 120
        if (loginWidth < 90) {
          navbarWidth = this.$refs.headerNavbar.$el.clientWidth - 100
        }
        this.$refs.headerNavbar.$children[0].$el.children[0].style.width = navbarWidth - (loginWidth + 10) + 'px'
        this.titleWidth = this.$refs.headerNavbar.$children[0].$el.children[0].clientWidth
        const subtitleWidth = navbarWidth - (loginWidth + this.originalTitle + 20)
        if (subtitleWidth > 150) {
          this.$refs.headerNavbar.$children[0].$el.children[0].firstElementChild.style.width = subtitleWidth + 'px'
        } else {
          this.$refs.headerNavbar.$children[0].$el.children[0].firstElementChild.style.width = 0
        }
        if (navbarWidth < 235 && loginWidth < 90) {
          this.$refs.headerNavbar.$children[0].$el.children[0].style.width = 0
        }
      }
    },
    getAvatar () {
      if (this.contrast) {
        return require('../assets/images/avatar-placeholder-negative.png')
      } else {
        return require('../assets/images/avatar-placeholder.png')
      }
    },
    checkUpdate () {
      AcademyApi.getUpdateInformation()
          .then(response => {
            this.showUpdateBanner = response.data.showUpdateBanner
            this.updateBannerText = response.data.updateBannerText
            this.updateInProgress = response.data.updateInProgress
          })
    },
    checkUser () {
      this.$store.dispatch('auth/checkUser')
        .then(response => {
          if (response) {
            if (this.expire) {
              clearInterval(this.checkUserInterval)
              this.userExpired()
            }
          } else {
            clearInterval(this.checkUserInterval)
            if (this.signedIn && !this.signingOut) {
              console.log('checkUser logout')
              this.userLogout()
            }
          }
        })
        .catch(error => {
          console.log(error)
          clearInterval(this.checkUserInterval)
          if (this.signedIn && !this.signingOut) {
            if (error.message && (error.message === 'Network Error' || error.message.includes('timeout'))) {
              this.$router.push({ name: 'login_path' })
            }
            this.userLogout()
          }
        })
    },
    fetchUser () {
      if (!this.signedIn) {
        return
      }
      this.$store.dispatch('auth/fetchUser')
        .then(response => {
          if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
            this.$router.push({ name: 'no_rights_path' })
          }
          this.buildLinks()
          setTimeout(() => {
            this.buildLinks()
          }, 300)
        })
        .catch(error => {
          console.log(error.message)
          this.$toastr.e(error.message)
        })
    },
    updateStorage () {
      const fn = `${localStorage.getItem('dnFN')} ${localStorage.getItem('dnLN')}`
      if (fn !== this.fullName) {
        this.$store.dispatch('auth/updateState')
        this.fetchUser()
        this.router.go(0)
      }
    },
    userExpired () {
      this.$store.dispatch('auth/signOut')
        .then(response => {
          this.$store.dispatch('auth/setSigninOut', false)
          // eslint-disable-next-line eqeqeq
          if (process.env.LOCAL_LOGIN == '1') {
            window.location = '/login'
          } else {
            window.location = '/'
          }
        })
        .catch(error => {
          if (error.message === 'Network Error') {
            window.location = '/login'
          }
          console.log(error.message)
          this.$toastr.e(error.message)
        })
    },
    userLogout () {
      let fullLogout = false
      if (this.signedIn && !this.expire) {
        fullLogout = true
        clearInterval(this.checkUserInterval)
      }
      Vue.$cookies.remove('evaluationId')
      this.$store.dispatch('auth/signOut')
        .then(response => {
          this.$store.dispatch('auth/setSigninOut', false)
          this.$nextTick(() => {
            // eslint-disable-next-line eqeqeq
            if (process.env.LOCAL_LOGIN == '1') {
              window.location = '/login'
            } else {
              // const idToken = response.data.idToken
              if (fullLogout) {
                // window.location = `${process.env.DN_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}&redirect_uri=${process.env.SERWER_ADDRESS_WITH_PROTOCOL}`
                window.location = `${process.env.DN_OP_END_SESSION_ENDPOINT}?post_logout_redirect_uri=${process.env.SERWER_ADDRESS_WITH_PROTOCOL}`
              } else {
                window.location = '/'
              }
            }
          })
        })
        .catch(error => {
          if (error.message === 'Network Error') {
            window.location = '/login'
          }
          console.log(error.message)
          this.$toastr.e(error.message)
        })
    },
    userWrongLogout () {
      this.$store.dispatch('auth/signOut')
        .then(response => {
          Vue.$cookies.remove('wrong_login')
          setTimeout(() => {
            window.location = `${process.env.DN_OP_END_SESSION_ENDPOINT}?post_logout_redirect_uri=${process.env.SERWER_ADDRESS_WITH_PROTOCOL}`
          }, 2000)
        })
        .catch(error => {
          console.log(error.message)
          this.$toastr.e(error.message)
        })
    },
    userLogin () {
      // eslint-disable-next-line eqeqeq
      // if (process.env.LOCAL_LOGIN == 1) {
      //   window.location = '/dev_login'
      // } else {
      //   window.location = `${process.env.SERWER_ADDRESS_WITH_PROTOCOL}/users/auth/openid_connect`
      // }
      window.location = `${process.env.SERWER_ADDRESS_WITH_PROTOCOL}/users/auth/openid_connect`
    },
    changeRole (role) {
      ApiAuth.setCurrentRole(role)
        .then(response => {
          this.$store.dispatch('auth/setCurrentRole', role)
          this.$store.dispatch('auth/setRights', response.data)
          this.$nextTick(() => {
            // this.$router.go()
            window.location = '/'
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    buildLinks () {
      if (process.env.APP_KIND === 'BEZ_ANALITYKI') {
        this.links = [
          {
            id: 3,
            name: this.$t('components.dn_navigation_menu.achievements'),
            path: 'achievement_list_path',
            iconClass: 'far fa-list-alt',
            roles: ['pracownik', 'kierownikdyscypliny', 'analityknaukowy', 'naukowiec'],
            sub: [
              {
                id: 31,
                name: this.$t('components.dn_navigation_menu.evidence'),
                path: 'achievement_list_path',
                iconClass: 'pcg-icon-home',
                roles: ['pracownik', 'kierownikdyscypliny', 'analityknaukowy']
              },
              {
                id: 33,
                name: this.$t('components.dn_navigation_menu.my_achievements'),
                path: 'scientist_publications_path',
                iconClass: 'pcg-icon-home',
                roles: ['naukowiec']
              }
            ]
          },
          {
            id: 6,
            name: this.$t('components.dn_navigation_menu.employees'),
            path: 'scientists_list_path',
            iconClass: 'fas fa-users',
            roles: ['pracownik', 'kierownikdyscypliny', 'analityknaukowy'],
            sub: []
          },
          {
            id: 9,
            name: this.$t('components.dn_navigation_menu.import_eksport'),
            path: 'achievement_list_path',
            iconClass: 'fas fa-file-import',
            roles: [],
            hide: !(this.hasRight('import_export')),
            sub: [
              {
                id: 92,
                name: this.$t('components.dn_navigation_menu.import_orcid'),
                path: 'orcid_publications_path',
                query: { id: this.$store.getters['auth/employeeId'] },
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('import_own_orcid'))
              }
            ]
          }
        ]
      } else {
        this.links = [
          {
            id: 1,
            name: this.$t('components.dn_navigation_menu.disciplines'),
            path: 'disciplines_path',
            iconClass: 'fas fa-flask',
            roles: [],
            hide: !(this.hasRight('list_discipline') || this.hasRight('list_own_discipline')),
            sub: []
          },
          {
            id: 2,
            name: this.$t('components.dn_navigation_menu.my_evaluation'),
            path: 'scientist_dashboard_path',
            iconClass: 'pcg-icon-home',
            roles: [],
            hide: !(this.hasRight('view_own_employee')),
            sub: []
          },
          {
            id: 3,
            name: this.$t('components.dn_navigation_menu.achievements'),
            path: 'achievement_list_path',
            iconClass: 'far fa-list-alt',
            roles: [],
            hide: !(this.hasRight('list_achievement') || this.hasRight('list_own_achievement')),
            sub: [
              {
                id: 31,
                name: this.$t('components.dn_navigation_menu.evidence'),
                path: 'achievement_list_path',
                iconClass: 'pcg-icon-home',
                roles: [],
                hide: !(this.hasRight('list_achievement'))
              },
              {
                id: 310,
                name: this.$t('components.dn_navigation_menu.new_achievement'),
                path: 'achievement_empty_path',
                iconClass: 'pcg-icon-home',
                roles: [],
                hide: !(this.hasRight('add_achievement'))
              },
              {
                id: 33,
                name: this.$t('components.dn_navigation_menu.my_achievements'),
                path: 'scientist_publications_path',
                iconClass: 'pcg-icon-home',
                roles: [],
                hide: !(this.hasRight('list_own_achievement'))
              }
            ]
          },
          {
            id: 4,
            name: this.$t('components.dn_navigation_menu.statements'),
            path: 'statements_list_path',
            iconClass: 'fas fa-tasks',
            roles: [],
            hide: !(this.hasRight('list_statements')),
            sub: []
          },
          {
            id: 5,
            name: this.$t('components.dn_navigation_menu.my_statements'),
            path: 'statements_list_path',
            iconClass: 'fas fa-tasks',
            roles: [],
            hide: !(this.hasRight('edit_own_statements')),
            sub: [
              {
                id: 51,
                name: this.$t('components.dn_navigation_menu.discipline_selection'),
                path: 'statements_discipline_path',
                query: { id: this.$store.getters['auth/employeeId'] },
                iconClass: 'pcg-icon-home',
                roles: [],
                hide: !(this.hasRight('edit_own_statements'))
              }
            ]
          },
          {
            id: 6,
            name: this.$t('components.dn_navigation_menu.employees'),
            path: 'scientists_list_path',
            iconClass: 'fas fa-users',
            roles: [],
            hide: !(this.hasRight('list_employees') || this.hasRight('edit_employee')),
            sub: [
              {
                id: 60,
                name: this.$t('components.dn_navigation_menu.list_of_employees'),
                path: 'scientists_list_path',
                iconClass: 'fas fa-users',
                roles: [],
                hide: !(this.hasRight('list_employees'))
              },
              {
                id: 61,
                name: this.$t('components.dn_navigation_menu.new_employee'),
                path: 'no_where_path',
                iconClass: 'fas fa-users',
                roles: [],
                hide: !(this.hasRight('add_employee')),
                clickFunc: () => {
                  this.$bvModal.show('new-employee-modal')
                }
              }
            ]
          },
          {
            id: 7,
            name: this.$t('components.dn_navigation_menu.instances'),
            path: 'academies_list_path',
            iconClass: 'fa fa-university',
            roles: [],
            hide: !(this.hasRight('list_academies') || this.hasRight('list_own_academy')) || (this.subDomain === 'global'),
            sub: []
          },
          {
            id: 8,
            name: this.$t('components.dn_navigation_menu.academies'),
            path: 'academies_list_path',
            iconClass: 'fa fa-university',
            roles: [],
            hide: !(this.hasRight('list_academies') || this.hasRight('list_own_academy')) || !(this.subDomain === 'global'),
            sub: []
          },
          {
            id: 9,
            name: this.$t('components.dn_navigation_menu.faculties'),
            path: 'faculties_list_path',
            iconClass: 'fas fa-bezier-curve',
            roles: [],
            hide: !(this.hasRight('list_unit')) || (this.subDomain === 'global'),
            sub: []
          },
          {
            id: 10,
            name: this.$t('components.dn_navigation_menu.evaluations'),
            path: 'evaluations_list_path',
            iconClass: 'fas fa-bars',
            roles: [],
            hide: !(this.hasRight('list_evaluation') || this.hasRight('edit_evaluation')),
            sub: []
          },
          {
            id: 11,
            name: this.$t('components.dn_navigation_menu.import_eksport'),
            path: 'achievement_list_path',
            iconClass: 'fas fa-file-import',
            roles: [],
            hide: !(this.hasRight('import_export')),
            sub: [
              {
                id: 1101,
                name: this.$t('components.dn_navigation_menu.import_orcid'),
                path: 'orcid_publications_path',
                query: { id: this.$store.getters['auth/employeeId'] },
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('import_own_orcid'))
              },
              {
                id: 1102,
                name: this.$t('components.dn_navigation_menu.import_pbn_xml'),
                path: 'pbn_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('import_pbn_xml'))
              },
              {
                id: 1103,
                name: this.$t('components.dn_navigation_menu.import_employees'),
                path: 'employee_import_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('import_employees'))
              },
              {
                id: 1104,
                name: this.$t('components.dn_navigation_menu.import_statements_of_publications'),
                path: 'publication_statement_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('import_publication_statements'))
              },
              {
                id: 1105,
                name: this.$t('components.dn_navigation_menu.import_art_achievements'),
                path: 'art_import_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('import_art_achievements'))
              },
              {
                id: 1106,
                name: this.$t('components.dn_navigation_menu.export_pbn_xml'),
                path: 'export_xml_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('export_pbn'))
              },
              {
                id: 1107,
                name: this.$t('components.dn_navigation_menu.integration_pbn'),
                path: 'pbn_token_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('generate_token_pbn'))
              },
              {
                id: 1108,
                name: this.$t('components.dn_navigation_menu.import_pbn_api'),
                path: 'import_pbn_api_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('import_pbn'))
              },
              {
                id: 1109,
                name: this.$t('components.dn_navigation_menu.import_publications_and_employees'),
                path: 'import_pbn_path',
                iconClass: 'fas fa-tasks',
                roles: ['analityknaukowy'],
                hide: !(this.hasRight('import_achievements_and_employees'))
              },
              {
                id: 1110,
                name: this.$t('components.dn_navigation_menu.reports'),
                path: 'reports_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('export_pbn'))
              },
              {
                id: 1111,
                name: this.$t('components.dn_navigation_menu.orcid_reports'),
                path: 'orcid_reports_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('export_orcid'))
              },
              {
                id: 1112,
                name: this.$t('components.dn_navigation_menu.import_polon'),
                path: 'import_polon_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('import_polon_achievements'))
              },
              {
                id: 1113,
                name: this.$t('components.dn_navigation_menu.import_polon_employees'),
                path: 'import_polon_employees_path',
                iconClass: 'fas fa-tasks',
                roles: [],
                hide: !(this.hasRight('import_polon_employees'))
              }
            ]
          },
          {
            id: 12,
            name: this.$t('components.abk_navigation_menu.dictionaries'),
            path: 'dictionary_acceptances_path',
            iconClass: 'fas fa-book',
            roles: [],
            hide: !(this.hasRight('list_dictionaries') || this.hasRight('list_own_dictionaries')),
            sub: [
              {
                id: 1201,
                name: this.$t('components.abk_navigation_menu.dictionaries'),
                path: 'dictionaries_path',
                roles: [],
                hide: !(this.hasRight('list_dictionaries') || this.hasRight('list_own_dictionaries')),
                sub: []
              },
              {
                id: 1203,
                name: this.$t('components.dn_navigation_menu.publishers'),
                path: 'publishers_list_path',
                roles: [],
                hide: !(this.hasRight('list_dictionaries') || this.hasRight('list_own_dictionaries')),
                sub: []
              },
              {
                id: 1204,
                name: this.$t('components.dn_navigation_menu.periodicals'),
                path: 'periodicals_list_path',
                roles: [],
                hide: !(this.hasRight('list_dictionaries') || this.hasRight('list_own_dictionaries')),
                sub: []
              },
              {
                id: 1205,
                name: this.$t('components.dn_navigation_menu.conferences'),
                path: 'conferences_list_path',
                roles: [],
                hide: !(this.hasRight('list_dictionaries') || this.hasRight('list_own_dictionaries')),
                sub: []
              },
              {
                id: 1206,
                name: this.$t('components.dn_navigation_menu.disciplines'),
                path: 'dictionary_disciplines_path',
                roles: [],
                hide: !(this.hasRight('list_dictionaries') || this.hasRight('list_own_dictionaries')),
                sub: []
              },
              {
                id: 1207,
                name: this.$t('components.dn_navigation_menu.kind_of_arts_achievements'),
                path: 'art_achievements_path',
                roles: [],
                hide: !(this.hasRight('list_dictionaries') || this.hasRight('list_own_dictionaries')),
                sub: []
              },
              {
                id: 1208,
                name: this.$t('components.dn_navigation_menu.financing_institutions'),
                path: 'financing_institutions_list_path',
                roles: [],
                hide: !(this.hasRight('list_own_dictionaries')) || (this.subDomain === 'global'),
                sub: []
              },
              {
                id: 1209,
                name: this.$t('components.dn_navigation_menu.entities_realizing_projects'),
                path: 'entity_projects_list_path',
                roles: [],
                hide: !(this.hasRight('list_own_dictionaries')) || (this.subDomain === 'global'),
                sub: []
              },
              {
                id: 1210,
                name: this.$t('components.dn_navigation_menu.financing_sources'),
                path: 'financing_sources_list_path',
                roles: [],
                hide: !(this.hasRight('list_own_dictionaries')) || (this.subDomain === 'global'),
                sub: []
              },
              {
                id: 1211,
                name: this.$t('components.dn_navigation_menu.import_pbn_dictionaries'),
                path: 'pbn_dictionaries_import_path',
                roles: ['manager'],
                hide: !(this.subDomain === 'global' || this.subDomain === 'public'),
                sub: []
              }
            ]
          },
          {
            id: 13,
            name: this.$t('components.dn_navigation_menu.rights'),
            path: 'rights_path',
            iconClass: 'fas fa-user-shield',
            roles: [],
            hide: !(this.hasRight('edit_roles') || this.hasRight('edit_own_roles')) || (this.subDomain === 'global'),
            sub: []
          },
          {
            id: 14,
            name: this.$t('components.dn_navigation_menu.users'),
            path: 'users_list_path',
            iconClass: 'fa fa-users',
            roles: [],
            hide: !(this.hasRight('list_users') || this.hasRight('list_own_users')),
            sub: []
          },
          {
            id: 15,
            name: this.$t('components.dn_navigation_menu.reports'),
            path: 'reports_path',
            iconClass: 'fas fa-tasks',
            roles: ['manager'],
            sub: []
          },
          {
            id: 16,
            name: this.$t('components.dn_navigation_menu.history'),
            path: 'histories_path',
            iconClass: 'fa fa-history',
            roles: [],
            hide: !(this.hasRight('view_history_report')),
            sub: []
          },
          {
            id: 17,
            name: this.$t('components.dn_navigation_menu.duplicate_reports'),
            path: 'duplicate_reports_path',
            iconClass: 'fas fa-tasks',
            roles: [],
            hide: !(this.hasRight('duplicate_report')),
            sub: []
          }
        ]
      }
      // eslint-disable-next-line eqeqeq
      if (process.env.NO_REPO == 1) {
        const idx = this.links.findIndex((e) => e.name === 'Import/Ekport')
        if (idx > -1) {
          const idx2 = this.links[idx].sub.findIndex((e) => e.name === 'Import DN PCG')
          if (idx2 > -1) {
            this.links[idx].sub.splice(idx2, 1)
          }
        }
      }
      // eslint-disable-next-line eqeqeq
      if (process.env.NO_PBN == 1) {
        const idx = this.links.findIndex((e) => e.name === 'Import/Ekport')
        if (idx > -1) {
          const idx2 = this.links[idx].sub.findIndex((e) => e.name === 'Import PBN XML')
          if (idx2 > -1) {
            this.links[idx].sub.splice(idx2, 1)
          }
          const idx3 = this.links[idx].sub.findIndex((e) => e.name === 'Import pracowników')
          if (idx2 > -1) {
            this.links[idx].sub.splice(idx3, 1)
          }
          const idx4 = this.links[idx].sub.findIndex((e) => e.name === 'Import PBN API')
          if (idx4 > -1) {
            this.links[idx].sub.splice(idx4, 1)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../assets/stylesheets/custom';
  .pcg-btn-sidebar-menu {
    height: 46px;
    width: 25px;
    align-items: center;
  }
  .header-title {
    margin-bottom: 20px;
    display: block;
    padding-top: 20px;
  }
  .icon-padding {
    color: $main-active-color;
    padding-right: 10px;
  }
  .username {
    margin-right: 18px;
  }
  @media screen and (min-width: 1051px) {
    .header-title {
      display: none;
    }
  }

  .my-pcg-header {
    ::v-deep .pcg-header-container {
       .pcg-logo-container {
        display: none !important;
      }
    }
  }

  ::v-deep .pcg-logo-container img {
    width: 100% !important;
    height: auto !important;
  }

  .my-page-container {
    ::v-deep .main-content-margin {
      padding-left: 0 !important;
    }
  }

  /deep/ .pcg-field {
    padding-top: 0;
    padding-bottom: 0;
    height: 2.14rem;
  }

  /deep/ .pcg-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  /deep/ .title-light {
    width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    height: 33px;
    vertical-align: middle;
  }

  .pcg-12 {
    /deep/ .title-light {
      position: relative;
      bottom: -.3rem;
    }
  }

  .pcg-14 {
    /deep/ .title-light {
      position: relative;
      bottom: -.1rem;
    }
  }

  .pcg-16 {
    /deep/ .title-light {
      position: relative;
      bottom: .1rem;
    }
  }

  .pcg-18 {
    /deep/ .title-light {
      position: relative;
      bottom: .2rem;
    }
  }

  .pcg-20 {
    /deep/ .title-light {
      position: relative;
      bottom: .3rem;
    }
  }

  @media screen and (max-width: 1051px) {
    /deep/ .header-title {
      display: inline-block;
    }
  }
  @media screen and (max-width: 767px) {
    /deep/ .header-title {
      margin-left: 1rem;
    }
  }
  @media screen and (max-width: 300px) {
    /deep/ .header-title {
      margin-left: 0;
    }
  }
  .skip-link {
    opacity: 0;
    margin-right: 1rem;
    color: $main-active-color;
    &:focus {
      opacity: 1;
    }
    a {
      color: unset;
      text-decoration: none !important;
      &:hover {
        color: unset;
        text-decoration: none !important;
      }
    }
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .update-text {
    text-align: center;
    color: $pcg-dark-gray;
    font-weight: bold;
    font-size: $font-size-l;
  }

  .update-banner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $pcg-orange;
    color: $pcg-white;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    z-index: 1050;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
</style>
<style lang="scss" scoped>
  @import '../assets/stylesheets/custom-contrast';
  .contrast {
    .icon-padding {
      color: $main-active-color;
    }
    .my-layout-header {
      /deep/ .header-background {
        background: transparent !important;
      }
    }
    .skip-link {
      color: $main-active-color;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .update-text {
      color: $pcg-dark-gray;
    }
    .update-banner {
      background-color: $pcg-orange;
      color: $pcg-white;
    }
  }
</style>
